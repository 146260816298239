import ReactGA4 from 'react-ga4';

ReactGA4.initialize(process.env.REACT_APP_GTM_CONTAINER_ID);

export const captureEvents = async ({event, custom = {}}) => {
    try {
        let userDetails = localStorage?.getItem('userDetails')
        const eventPayload = {
            "email_id": userDetails?.emailId,
            "user_id": userDetails?.userId,
            "user_name": userDetails?.name,
            ...custom
        }
        ReactGA4.event(event, eventPayload);
    } catch (error) {   
        // console.log(error)
    }
}