import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from './pages/AppLayout';

function App() {
  return (
    <div className="App h-screen">
      
      <BrowserRouter>
        <Routes>
            <Route path="/360" element={<AppLayout/>} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
