import React, { Suspense, lazy, useEffect, useState } from "react";
import Tabs from "../Components/common/Tabs";
import Loader from "../Components/common/Loader";
import { ASSETS, IFRAME_MODULE_CONFIG, TAB_ENUM, TAB_OPTIONS } from "../Components/common/config";
import { getDynamicTab, getDynamicTabImage } from "../utils/getDynamicTab";
import centralAPI from "../CentralAPIHandler/CentralAPIHandler";
import { getParams } from "../utils/getParams";
import { transformedSku360Data } from "../utils/transformAPIData";
import { IMAGE_COMPRESSOR } from "../utils/imageCompressor";

const View360Common = lazy(() => import("../Components/View360/View360Common"));
const Video = lazy(() => import("../Components/Video/Video"));
const Gallery = lazy(() => import("../Components/gallery/Gallery"));

const CommonIframeHome = () => {
  
  const [skuData, setSkuData] = useState([]);
  const [_360Data, set360Data] = useState({});
  const [loading, setLoading] = useState(false);
  const [dynamicTab, setDynamicTab] = useState([]);
  const [dynamicTabImage, setDynamicTabImage] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TAB_ENUM.THREE_SIXTY_VIEW);
  const {params} = getParams()

  useEffect(() => {
    const fetchApidata = async () => {
      try {
        setLoading(true)
        const response = await centralAPI.handleGetRequest(`${process.env.REACT_APP_BASEURL_API_SPYNE}/api/nv1/hotspot/sku-data`, params); 
        setSkuData(response?.data)
        set360Data(transformedSku360Data(response?.data))
        const getTabBasedOnConfig = getDynamicTab(response?.data)
        setDynamicTab(getTabBasedOnConfig);
        setDynamicTabImage(getDynamicTabImage(response?.data?.image));
        setLoading(false)
        if (getTabBasedOnConfig.length > 0) {
          setSelectedTab(getTabBasedOnConfig[0].key);
        }
      } catch (error) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    };

    fetchApidata();
  }, []);

  return (
    <div className=" flex flex-col items-center justify-center h-full w-full transition-all duration-700 ease-in">
      <div className=" relative border rounded-md h-full w-full ">
        {(!loading && (skuData?.status?.toLowerCase() !== IFRAME_MODULE_CONFIG.isSkuDone)) ? 
          <img className="w-full h-full max-sm:object-contain" src={IMAGE_COMPRESSOR({url:ASSETS.other.noSkuDone})} alt="vin is in process"/>
          :
          <>
        {loading ? 
        <Loader/>
        :
          <Suspense fallback={<Loader showPageName={selectedTab} />}>
          {selectedTab === TAB_ENUM.THREE_SIXTY_VIEW ? (
            <View360Common data={_360Data}/>
          ) : selectedTab === TAB_ENUM.VIDEO_VIEW ? (
            <Video videoUrl={skuData?.video} />
          ) : selectedTab === TAB_ENUM.GALLERY_VIEW ? (
            <Gallery galleryData={Object.values(skuData?.image).flat()} tabLength={dynamicTab?.length} dynamicTabImage={dynamicTabImage}/>
          ) : null}
        </Suspense>}
       {(dynamicTab?.length > 1) && <div className={` fixed bottom-0 md:bottom-[3.2%]  left-0 md:left-12 bg-blue-lightestBlue shadow-md shadow-black-8 sm:rounded-b-md md:rounded-2xl max-md:w-full md:w-max`}>
          <Tabs
            options={dynamicTab}
            onSelect={(option) => setSelectedTab(option.key)}
          />
        </div>}
        </>
        }
      </div>
    </div>
  );
};

export default CommonIframeHome;
